// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
import i18next from 'i18next';
export default function enhanceMessage(message = '', code?: number) {
  switch (true) {
    case code === 20101: // Invalid token error
      return message + i18next.t('invalidCredentials');
    case message === 'Permission denied by system':
      return i18next.t('screenSharing.permissionError');
    default:
      return message;
  }
}
